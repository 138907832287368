import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import MatomoTrackerProvider from "./components/MatomoTrackerProvider";

const Root = () => (
    <MatomoTrackerProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </MatomoTrackerProvider>
);

ReactDOM.render(<Root/>, document.getElementById('root'));
