import React from "react";
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";

const tracker = createInstance({
    urlBase: 'https://analytics.vx.igd.fraunhofer.de/',
    siteId: 7, // optional, default value: `1`
})

const MatomoTrackerProvider : React.FunctionComponent<{}> = (props) => {
    return (
        <MatomoProvider value={tracker}>
            {props.children}
        </MatomoProvider>
    );
}

export default MatomoTrackerProvider;