import * as React from 'react';

import "./Footer.css";

const Footer : React.FunctionComponent<{}> = () => (
    <div id="footer">
        <div className="footer-items">
            <div className="footer-item">
                <span>PAVED is a project maintained by <a href="https://www.igd.fraunhofer.de/kompetenzen/technologien/visual-analytics">Fraunhofer IGD</a> under the lead of <a href="https://www.igd.fraunhofer.de/en/institute/about-us/staff/lena-cibulski">Lena Cibulski</a></span>
            </div>
            <div className="footer-item">
                <a href='/imprint'>Imprint</a>
            </div>
            <div className="footer-item">
                <a href='/dsgvo'>GDPR</a>
            </div>
        </div>
    </div>
);

export default Footer;
