import * as React from 'react';
import {useEffect, useRef} from "react";

type Props = {
    id?: string,
    label?: string,
    options: Array<string>,
    optionHint?: string,
    defaultOption?: string,
    fireInitialOnChange?: boolean,
    optionChangeHandler(option: string): void
};

const DropDownSelector : React.FunctionComponent<Props> = props => {
    const selectElement = useRef<HTMLSelectElement>(null);

    const optionChangeHandler = props.optionChangeHandler;
    const options = JSON.stringify(props.options); // Array needs to be stringified to deal with shallow comparison
    useEffect(() => {
        // Inform parent element about initially focused dropdown value
        if(props.fireInitialOnChange === undefined || props.fireInitialOnChange) {
            let currOption = selectElement.current!.value;
            optionChangeHandler(currOption);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    let defaultValue = props.optionHint ? props.optionHint : props.defaultOption;

    return(
        <div id={props.id}
             style={{"display":"flex", "justifyContent":"center"}}>
            <Label label={props.label}/>
            <select ref={selectElement}
                    onChange={event => props.optionChangeHandler(event.target.value)}
                    value={defaultValue}
                    style={{"height": "30px"}}>
                <OptionHint optionHint={props.optionHint}/>
                {
                    props.options.map((objectiveName : string, i : number) =>
                        <option key={i} value={objectiveName}>{objectiveName}</option>)
                }
            </select>
        </div>
    );
};

function Label({label}: any) {
    if(label)
        return (<span style={{"marginRight": "3px"}}>{label}</span>);
    else
        return (<div style={{"display" : "none"}}/>);
}

function OptionHint({optionHint}: any) {
    if(optionHint)
        return( <option disabled value={optionHint}>{optionHint}</option> );
    else
        return null;
}

export default DropDownSelector;