import * as React from 'react';
import {Nav, Navbar, NavbarBrand/*, NavLink, NavbarText*/} from 'reactstrap';
//import { NavLink as RRNavLink } from 'react-router-dom'
import {Routes} from "../config/routes";
import "./Header.css";
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Header : React.FunctionComponent<{}> = () => {
    return (
        <div id="header">
            <Navbar light expand="xl" fixed="top">
                <NavbarBrand href={Routes.root}>PAVED</NavbarBrand>
                <Nav className="ml-auto mr-auto" navbar>
                </Nav>
                {/*<NavbarText>
                    <NavLink tag={RRNavLink} to={Routes.christmas}><FontAwesomeIcon icon="star" className="icon "/></NavLink>
                </NavbarText>*/}
            </Navbar>
        </div>
    );
};

export default Header;
