import React, { useEffect } from 'react';
import {Route, useLocation} from 'react-router-dom';
import {Routes} from './config/routes';
import Header from './layouts/Header';
import LandingPage from "./pages/LandingPage/LandingPage";
import {GDPR, Imprint} from "@iva/page-templates-react";
import PAVED from "./pages/PAVED/PAVED";

import "bootstrap/dist/css/bootstrap.min.css" // Because Reactstrap does not include Bootstrap CSS
import './App.css';

import {library} from '@fortawesome/fontawesome-svg-core';
import {faChevronDown, faStar, faTable, faTimes} from '@fortawesome/free-solid-svg-icons';
//import Christmas from "./pages/Christmas/Christmas";
import {useMatomo} from "@datapunt/matomo-tracker-react";
library.add(faChevronDown, faStar, faTable, faTimes);

const App : React.FunctionComponent<{}> = () => {

    let location = useLocation();
    const {trackPageView} = useMatomo();
    useEffect(() => {
        trackPageView({
            href: location.pathname
        });
    }, [location, trackPageView]);

    return (
        <div id="app">
            <Header />
            <main className="page-content">
                <Route exact path={Routes.root} component={() => <LandingPage/>} />
                <Route path={Routes.demo} component={() => <PAVED/>} />
                {/*<Route path={Routes.christmas} component={() => <Christmas/>} />*/}
                <Route path={Routes.dsgvo} component={() => <GDPR german={false}
                                                                  projectName={"PAVED"}
                                                                  projectUrl={"https://paved.iva.igd.fraunhofer.de/"}
                                                                  contactFormEnabled={false}
                                                                  transfer={false}
                                                                  webanalytics={{googleanalytics: false, wireminds: false, matomo: true}}
                                                                  youtube={false}
                                                                  social={{facebook: false, pinterest: false, twitter: false, googleplus: false, xing: false}}/>} />
                <Route path={Routes.imprint} component={() => <Imprint/>} />
            </main>
        </div>
    );
};

export default App;
