import {ATTRIBUTE_TYPE, COLOR_MAP, OPTIMIZATION_TYPE, Row, SingleTableData, Table} from "./constants";

export default class Utils {
    static deserializeJSON(file: any, ignoreAttributes?: Array<string>): SingleTableData {
        // Parse tables contained in file
        let parsedTables: Array<Table> = [];
        file.tables.forEach((table: any) => {
            if (!table.name) {
                throw new Error("Could not read file. Unique table identifier \"name\" not specified.");
            }
            if (!table.columns) {
                throw new Error("Could not read file. \"columns\" not specified.");
            }
            if (!table.rows) {
                throw new Error("Could not read file. \"rows\" not specified.");
            }

            let columns = table.columns;
            let rows = table.rows;

            // If attribute "ID" is not given in file: generate ID attribute and autoincremented IDs for each item
            let IDsGenerated = false;
            rows.forEach((row: Row, i: number) => {
                if (!("id" in row || "ID" in row || "Id" in row)) {
                    (row as any)["ID"] = i;
                    IDsGenerated = true;
                }
            });
            if (IDsGenerated)
                columns.unshift({"name": "ID"});

            // Compute metadata per attribute across all rows
            if (ignoreAttributes && ignoreAttributes.length > 0)
                columns = columns.filter((attr: any) => {
                    return !ignoreAttributes.includes(attr.name);
                });
            let enrichedCols = columns.map((attr: any, i: number) => {
                let attribute = attr;

                // Determine attribute type
                let attr_values = rows.map((row: Row) => {
                    return (row as any)[attribute.name];
                });
                let type: ATTRIBUTE_TYPE | null = attr_values.every((val: any) => typeof (val) === "number" || val === "NaN") ? ATTRIBUTE_TYPE.NUMERICAL :
                    (attr_values.every((val: any) => typeof (val) === "string") ? ATTRIBUTE_TYPE.NOMINAL : null);
                if (type === null)
                    throw new Error("Could not read file. Could not determine attribute type of " + attribute.name + ".");

                attribute["attr_type"] = type;

                switch (attribute.attr_type) {
                    // If categorical, compute available categories and set min/max to non-defined
                    case ATTRIBUTE_TYPE.NOMINAL:
                        attribute["categories"] = attr_values.filter((value: string, index: number, self: any) => self.indexOf(value) === index);
                        attribute["min"] = -1;
                        attribute["max"] = -1;
                        break;
                    // If numerical, compute min and max statistic
                    case ATTRIBUTE_TYPE.NUMERICAL:
                        attribute["min"] = Math.min.apply(Math, attr_values.filter((val: any) => val !== "NaN"));
                        attribute["max"] = Math.max.apply(Math, attr_values.filter((val: any) => val !== "NaN"));
                        break;
                    default:
                        throw new Error("Attribute type could not be determined.");
                }

                // Optimization type, if applicable
                if (attr.obj) {
                    let optimizationType: OPTIMIZATION_TYPE;
                    switch (attr.obj) {
                        case "MAX":
                            optimizationType = OPTIMIZATION_TYPE.MAX;
                            break;
                        case "MIN":
                            optimizationType = OPTIMIZATION_TYPE.MIN;
                            break;
                        default:
                            optimizationType = OPTIMIZATION_TYPE.NONE;
                            break;
                    }
                    attribute.obj = optimizationType;
                    attribute["color"] = COLOR_MAP[i % COLOR_MAP.length];
                }

                return attribute;
            });

            parsedTables.push({name: table.name, rows: rows, columns: enrichedCols});
        });

        // No data contained
        if (parsedTables.length === 0) {
            throw new Error("File is empty.");
        }
        // Single table
        if (parsedTables.length === 1) {
            return {table: parsedTables[0]};
        }
        else
            throw new Error("Cannot parse non-single table!");
    }
}