import * as React from "react";
import {useState} from "react";
import data from "../../assets/data/M17010-5O.json";
import Utils from "../../config/Utils";
import {Attribute, COLOR_CODING, OPTIMIZATION_TYPE, Table} from "../../config/constants";
import DropDownSelector from "../../components/DropDownSelector";
import {ColorCodingHelper, MultiCriteriaParallelCoordinates} from "@iva/parallel-coordinates";
import {Content, Sidebar, SidebarItem, SidebarLayout, SwipeableDrawer} from "@iva/page-templates-react";
import TableView from "../../components/TableView/TableView";

type State = {
    flaggedRows: Array<number>,
    selectedObjectives: Array<string>,
    violinPlotsEnabled: boolean,
    curveSmoothingEnabled: boolean,
    colorCoding: ColorCodingHelper
};

const table: Table = Utils.deserializeJSON(data).table;
const paddingLeftSubsection = "15px";

const PAVED : React.FunctionComponent<{}> = () => {
    const [state, setState] = useState<State>({
        flaggedRows: [],
        selectedObjectives: table.columns.filter(attribute => attribute.obj !== undefined).map(objective => objective.name),
        violinPlotsEnabled: false,
        curveSmoothingEnabled: false,
        colorCoding: new ColorCodingHelper().setType(COLOR_CODING.GRADIENT_BRUSH)
    });

    return(
        <div style={{width: "100%"}}>
            <SidebarLayout>
                <Sidebar>
                    <SidebarItem title={"Data"}>
                        {renderAttributeCheckboxes()}
                    </SidebarItem>
                    <SidebarItem title={"Visualization"}>
                        {renderSmoothingCheckbox()}
                        {renderViolinPlotsCheckbox()}
                        {renderColorCodingControl()}
                    </SidebarItem>
                </Sidebar>
                <Content>
                    <MultiCriteriaParallelCoordinates rows={table.rows}
                                                      dimensions={table.columns.filter(attribute => state.selectedObjectives.includes(attribute.name))}
                                                      violinPlotsEnabled={state.violinPlotsEnabled}
                                                      curveSmoothingEnabled={state.curveSmoothingEnabled}
                                                      colorCodingHelper={state.colorCoding}
                                                      selectedCategories={[]}
                                                      onFlaggedIDsChange={(ids: Array<number>) => setState(prevState => ({...prevState, flaggedRows: ids}))}/>
                </Content>
            </SidebarLayout>
            <SwipeableDrawer icon={['fas', 'table']} description={state.flaggedRows.length + "/" + table.rows.length + " (flagged/total)"}>
                <TableView id={"data-table"}
                           rows={table.rows}
                           highlightedRows={state.flaggedRows}
                           columns={table.columns.map((attribute: Attribute) => {return {"ref": attribute.name};})}/>
            </SwipeableDrawer>
        </div>
    );

    /* ATTRIBUTE CHECKBOXES START */

    function renderAttributeCheckboxes() {
        let objectives = table.columns.filter(attribute => attribute.obj !== undefined);
        let inputAttributes = table.columns.filter(attribute => attribute.obj === undefined && attribute.name !== "ID");
        let style = inputAttributes.length > 0 ? {"display": "display"} : {"display" : "none"};

        return(
            <div>
                {objectives.map((objective: Attribute, i: number) =>
                    <div key={i} id="attributes-container">
                        <input type="checkbox"
                               onChange={() => toggleAttribute(objective)}
                               checked={state.selectedObjectives.includes(objective.name)}/>
                        {objective.name}
                    </div>
                )}
                <div style={style}>
                    <label className="switch">
                        <input type="checkbox"
                               onChange={event => allInputAttributesHandler(event.target.checked)}/>
                        <span className="slider round"/>
                        <span className="label">Input Attributes</span>
                    </label>
                </div>
                <div id="input-attributes"
                     style={{"paddingLeft" : paddingLeftSubsection, "display" : "none"}}>
                    {inputAttributes.map((attribute: Attribute, i: number) =>
                        <div key={i}>
                            <input type="checkbox"
                                   onChange={() => toggleAttribute(attribute)}
                                   checked={state.selectedObjectives.includes(attribute.name)}/>
                            {attribute.name}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function toggleAttribute(objective: Attribute) {
        let idx = state.selectedObjectives.indexOf(objective.name);
        let selected = state.selectedObjectives.slice();

        // Is currently selected => deselect
        if(idx > -1)
            selected.splice(idx, 1);
        // Is newly selected
        else
            selected.push(objective.name);

        setState(prevState => ({...prevState, selectedObjectives: selected}));
    }

    function allInputAttributesHandler(enable: boolean) {
        let selected = state.selectedObjectives.slice();

        let inputCheckboxes = document.getElementById("input-attributes")!;

        // Remove all input attributes from list of selected and hide checkboxes
        if(!enable) {
            selected = selected.filter((attributeName: string) => {
                let attribute = table.columns.find((attribute: Attribute) => attribute.name === attributeName)!;
                return attribute.obj !== undefined;
            });
            inputCheckboxes.style.display = "none";
        }
        // Add all input attributes to list of selected if not there already and show checkboxes
        else {
            let inputAttributes = table.columns.filter(attribute => attribute.obj === undefined);
            inputAttributes.forEach((attribute: Attribute) => {
                let idx = selected.indexOf(attribute.name);
                if(idx < 0)
                    selected.push(attribute.name);
            });

            inputCheckboxes.style.display = "block";
        }

        setState(prevState => ({...prevState, selectedObjectives: selected}));
    }

    /* ATTRIBUTE CHECKBOXES END */

    /* SMOOTHING AND VIOLIN PLOT CHECKBOXES START */

    function renderSmoothingCheckbox() {
        return(
            <div>
                <label className="switch">
                    <input type="checkbox"
                           onChange={event => curveSmoothingHandler(event.target.checked)}/>
                    <span className="slider round"/>
                    <span className="label">Curve Smoothing</span>
                </label>
            </div>
        );
    }

    function curveSmoothingHandler(enable: boolean) {
        setState(prevState => ({...prevState, curveSmoothingEnabled: enable}));
    }

    function renderViolinPlotsCheckbox() {
        return(
            <div>
                <label className="switch">
                    <input type="checkbox"
                           onChange={event => violinPlotsHandler(event.target.checked)}/>
                    <span className="slider round"/>
                    <span className="label">Violin Plots</span>
                </label>
            </div>
        );
    }

    function violinPlotsHandler(enable: boolean) {
        setState(prevState => ({...prevState, violinPlotsEnabled: enable}));
    }

    /* SMOOTHING AND VIOLIN PLOT CHECKBOXES END */

    /* COLOR CODING CHECKBOXES START */

    function renderColorCodingControl() {
        let colorCodingType = state.colorCoding.getType();

        let gradientBrushChecked = colorCodingType === COLOR_CODING.GRADIENT_BRUSH;
        let showGradientAttributeContainer = gradientBrushChecked ? "block" : "none";

        return(
            <div>
                <div>
                    <label className="switch">
                        <input type="checkbox"
                               onChange={event => enableColorCoding(event.target.checked)}/>
                        <span className="slider round"/>
                        <span className="label">Gradient Brush</span>
                    </label>
                </div>
                <div id="color-coding-container"
                     style={{"paddingLeft" : paddingLeftSubsection, "display" : "none"}}>
                    <div style={{"display":"inline-block"}}>
                        <div id="gradient-attribute"
                             style={{"display" : showGradientAttributeContainer}}>
                            <DropDownSelector options={table.columns.filter(attribute => state.selectedObjectives.includes(attribute.name) && attribute.obj !== undefined).map(objective => objective.name)}
                                              optionChangeHandler={(attribute: string) => colorCodingAttributeHandler(COLOR_CODING.GRADIENT_BRUSH, attribute)}
                                              fireInitialOnChange={true}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function enableColorCoding(enable: boolean) {
        // Show/hide color-coding control
        let colorCodingControl = document.getElementById("color-coding-container")!;
        colorCodingControl.style.display = enable ? "block" : "none";

        // Enable/disable color-coding of lines
        if(!enable) {
            // Deep copy of class instance
            let colorCoding = Object.create(state.colorCoding);
            let currType = colorCoding.getType();
            colorCoding.disable();
            colorCoding.setType(currType);
            setState(prevState => ({...prevState, colorCoding: colorCoding}));
        } else {
            // Handle color-coding
            colorCodingTypeHandler(COLOR_CODING.GRADIENT_BRUSH, true);
        }
    }

    function colorCodingTypeHandler(type : COLOR_CODING, enable?: boolean) {
        let attributeName : string;
        let attributeDropDown : any;
        switch(type) {
            case COLOR_CODING.GRADIENT_BRUSH:
                attributeDropDown = document.getElementById("gradient-attribute")!;
                attributeName = (attributeDropDown.getElementsByTagName("select")[0] as HTMLSelectElement).value;
                break;
            case COLOR_CODING.CATEGORIES:
                attributeDropDown = document.getElementById("topologies")!;
                attributeName = (attributeDropDown.getElementsByTagName("select")[0] as HTMLSelectElement).value;
                break;
            default:
                attributeName = "";
        }

        colorCodingAttributeHandler(type, attributeName, enable);
    }

    function colorCodingAttributeHandler(type: COLOR_CODING, attributeName: string, enable?: boolean) {
        let attribute = table.columns.find((attribute: Attribute) => attribute.name === attributeName);
        if(attribute) {
            // Deep copy of class instance
            let colorCoding = Object.create(state.colorCoding);
            if(enable !== undefined) {
                if(enable)
                    colorCoding.enable();
                else
                    colorCoding.disable();
            }
            // Set color scale
            let scaleConf = {};
            switch(type) {
                case COLOR_CODING.GRADIENT_BRUSH:
                    let domain = [attribute.min, attribute.max];
                    let minimization = attribute.obj === OPTIMIZATION_TYPE.MIN;
                    scaleConf = {
                        "domain" : domain,
                        "minimization" : minimization
                    };
                    colorCoding.setColorScale(type, attribute, scaleConf);
                    break;
                default:
                    break;
            }

            setState(prevState => ({...prevState, colorCoding: colorCoding}));
        }
    }
};

export default PAVED;