import * as React from 'react';
import {Routes} from "../../config/routes";
import Footer from "../../layouts/Footer";
import image from "../../assets/img/PAVED-Paper-Preview.png";

import "./LandingPage.css"
import {Tooltip} from "reactstrap";
import { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const LandingPage : React.FunctionComponent<{}> = () => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    return(
        <div style={{width: "100%"}}>
            <div className="page-container" style={{width: "70%"}}>
                <h1>PAVED</h1>
                <h2>
                    PAVED is an interactive visualization<br/>
                    built to ease cost-benefit trade-offs.
                </h2>
                <a id="live-demo" className="button" href={Routes.demo}>Live Demo</a>
                <Tooltip isOpen={tooltipOpen}
                         arrowClassName={"arrow-demo-tooltip"}
                         innerClassName={"inner-demo-tooltip"}
                         placement="right"
                         offset={"10px"}
                         target={"live-demo"}
                         toggle={() => setTooltipOpen(true)}>
                    <FontAwesomeIcon id="tooltip-close" icon="times" className="icon" onClick={() => setTooltipOpen(false)}/>
                    <strong>Electric Motors</strong><br/>
                    <span>Which of the 168 electric motors offers the best compromise?
                    Compare five quality criteria and nine geometry parameters to decide.<br/>
                    The data is provided by <a href={"https://www.lcm.at/en/electrical-drives/"} target="_blank" rel="noreferrer">LCM</a>.</span>
                </Tooltip>
                <div className="publication">
                    <div>
                        <img src={image} width={300} alt=""/>
                    </div>
                    <div id="description">
                        <p>
                            PAVED is based on the journal paper<br/>
                            Lena Cibulski, Hubert Mitterhofer, Thorsten May, Jörn Kohlhammer<br/>
                            <strong><a href={"https://onlinelibrary.wiley.com/doi/pdf/10.1111/cgf.13990"} target="_blank" rel="noreferrer">PAVED: Pareto Front Visualization for Engineering Design</a></strong><br/>
                            Computer Graphics Forum, 2020</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default LandingPage;